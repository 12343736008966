import { RELATIVE_REMINDERS } from 'customer-data-objects/task/TaskPropertyNames';
import { useCallback } from 'react';
import { TASK_INTERACTION_TYPES } from '../constants/table';
import { useDispatch } from '../context/Redux';
import { createFollowUpTask } from '../redux/actions/tasksCRUD';
import { useTaskDefaults } from './useTaskDefaults';
import { useTemporarilyExcludeIds } from './useTemporarilyExcludeIds';
export function useCreateFollowUpTask() {
  const taskDefaults = useTaskDefaults();
  const dispatch = useDispatch();
  const temporarilyExcludeTaskIds = useTemporarilyExcludeIds();
  const onCreateFollowUpTask = useCallback((completedTask, newDueDateValue) => {
    const {
      objectId
    } = completedTask;
    const relativeReminderValue = taskDefaults[RELATIVE_REMINDERS] || [];
    if (!objectId) {
      return;
    }
    dispatch(createFollowUpTask({
      taskIdToFollowUpOn: objectId,
      timestamp: newDueDateValue,
      relativeReminders: relativeReminderValue
    })).catch(err => console.error(err));
    temporarilyExcludeTaskIds([objectId], TASK_INTERACTION_TYPES.FOLLOW_UP_TASK);
  }, [dispatch, temporarilyExcludeTaskIds, taskDefaults]);
  return onCreateFollowUpTask;
}