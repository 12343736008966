import { useCallback } from 'react';
import { useDispatch, useSelector } from '../context/Redux';
import { setSearchText } from '../redux/actions/tempViewState';
import { tempViewStateSelector } from '../redux/selectors/tempViewState';
import { useActiveViewId } from './viewTabs';
export function useSearchText() {
  const dispatch = useDispatch();
  const viewId = useActiveViewId();
  const tempViewState = useSelector(tempViewStateSelector)[viewId];
  const setSearchTextCallback = useCallback(newSearchText => {
    dispatch(setSearchText({
      searchText: newSearchText,
      viewId
    }));
  }, [dispatch, viewId]);
  return {
    searchText: tempViewState ? tempViewState.searchText : '',
    setSearchText: setSearchTextCallback
  };
}