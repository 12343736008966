import { useCallback } from 'react';
import { useDispatch, useSelector } from '../context/Redux';
import { DEFAULT_PAGE_SIZE } from '../constants/table';
import { TASK_TABLE_PAGE_SIZE } from '../constants/userSettingsKeys';
import { saveLocalStorageUserSetting } from '../redux/actions/userSettings';
import { createUserSettingWithLoadingSelector } from '../redux/selectors/userSettings';
const taskTablePageSizeSelector = createUserSettingWithLoadingSelector(TASK_TABLE_PAGE_SIZE);
export const useTaskTablePageSize = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    value: pageSize = DEFAULT_PAGE_SIZE
  } = useSelector(taskTablePageSizeSelector);
  const onSetTaskTablePageSize = useCallback(newPageSize => {
    dispatch(saveLocalStorageUserSetting({
      settingKey: TASK_TABLE_PAGE_SIZE,
      settingValue: newPageSize
    }));
  }, [dispatch]);
  return {
    isLoading,
    pageSize,
    onSetTaskTablePageSize
  };
};