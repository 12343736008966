// @ts-expect-error untyped
import { mapTaskAPIV2ResponseToTaskRecord } from 'customer-data-objects/task/mapTaskAPIResponseToTaskRecord';
import http from 'hub-http/clients/apiClient';
import once from 'transmute/once';
import { formatAssociationsForTaskV2Request, formatPropertiesForTaskV2Request, mapTaskRecordToPOST } from '../formatters/taskObject';
import { makeQuickFetchRequest } from './quickFetch';
const getPropertiesSourceHeaders = once(() => ({
  'X-Properties-Source': 'CRM_UI'
}));
export function getTaskById(id, properties) {
  return makeQuickFetchRequest({
    requestName: `single-task-${id}`,
    makeRequestFn: () => http.get(`tasks/v2/tasks/${id}`, {
      query: {
        properties
      }
    }),
    removeAfterFetch: true
  }).then(mapTaskAPIV2ResponseToTaskRecord);
}
export function postTaskRecord(taskRecord) {
  const {
    associations,
    properties
  } = mapTaskRecordToPOST(taskRecord);
  return http.post('tasks/v2/tasks', {
    data: {
      associations,
      properties
    },
    headers: getPropertiesSourceHeaders()
  }).then(mapTaskAPIV2ResponseToTaskRecord);
}

/**
 * Sends a PATCH to update a task with the given objectId.
 * Can take a whole TaskRecord if you want to set the entire object.
 * @param objectId
 * @param associations can be nullish or the entire list of associations you want to set. Expects associations to be in simple format.
 * @param properties can be nullish or an map of property updates to merge
 * @param updateTemplate - whether to update all future occurrences of the given task if it is repeating
 * @returns Promise<TaskRecord>
 */
export function updateTask({
  objectId,
  associations,
  properties
}, {
  updateTemplate
} = {}) {
  return http.patch(`tasks/v2/tasks/${objectId}`, {
    data: {
      associations: associations ? formatAssociationsForTaskV2Request(associations) : null,
      properties: properties ? formatPropertiesForTaskV2Request(properties) : null
    },
    // the V2 API changed this param to be called updateSeries
    // TODO: can rename all references to updateSeries once everywhere uses tasks/V2 but for
    // now just renaming here so it's not confusing having two params floating in the codebase
    query: {
      updateSeries: updateTemplate
    },
    headers: getPropertiesSourceHeaders()
  }).then(mapTaskAPIV2ResponseToTaskRecord);
}
export function deleteTask(taskId, {
  stopRepeating
} = {}) {
  return http.delete(`tasks/v2/tasks/${taskId}`, {
    headers: getPropertiesSourceHeaders(),
    query: {
      stopRepeating
    }
  });
}