import { STATUS } from 'customer-data-objects/task/TaskPropertyNames';
import { Map as ImmutableMap } from 'immutable';
import { useCallback } from 'react';
import { COMPLETED } from '../../legacy/legacyBoard/crm_ui/constants/engagementStatus';
import { TASK_INTERACTION_TYPES } from '../constants/table';
import { useDispatch } from '../context/Redux';
import { updateTask } from '../redux/actions/tasksCRUD';
import { useTemporarilyExcludeIds } from './useTemporarilyExcludeIds';
export function useOnUpdateTaskStatus() {
  const dispatch = useDispatch();
  const temporarilyExcludeTaskIds = useTemporarilyExcludeIds();
  return useCallback(async ({
    objectId,
    newStatus,
    onFailure = () => {},
    onSuccess = () => temporarilyExcludeTaskIds([objectId], newStatus === COMPLETED ? TASK_INTERACTION_TYPES.COMPLETE_TASKS : TASK_INTERACTION_TYPES.UNCOMPLETE_TASKS)
  }) => {
    const arg = {
      objectId,
      propertyUpdates: ImmutableMap({
        [STATUS]: {
          name: STATUS,
          value: newStatus
        }
      }),
      onSuccess,
      onFailure
    };
    await dispatch(updateTask(arg));
  }, [dispatch, temporarilyExcludeTaskIds]);
}