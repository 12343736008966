import { registerQuery, useQuery } from 'data-fetching-client';
import { getViewById } from '../api/views';
import { useDispatch } from '../context/Redux';
import { seedView } from '../redux/actions/tempViewState';
export const viewFieldName = 'view';
export const viewQuery = registerQuery({
  fieldName: viewFieldName,
  args: ['viewId'],
  fetcher: getViewById
});
export function useFetchViewById({
  skipQuery = false,
  viewId
}) {
  const dispatch = useDispatch();
  return useQuery(viewQuery, {
    variables: {
      viewId: viewId
    },
    fetchPolicy: 'cache-first',
    onCompleted: response => void dispatch(seedView({
      viewId: response.view.id,
      view: response.view
    })),
    skip: skipQuery || !viewId
  });
}