import { getProperty } from 'customer-data-objects/record/ObjectRecordAccessors';
import { CREATED_DATE } from 'customer-data-objects/task/TaskPropertyNames';
import { useCallback, useMemo } from 'react';
import { BULK_ACTION_LAG_TIME } from '../constants/table';
import { TASKS_POLL_INTERVAL } from '../constants/polling';
import { useDispatch, useSelector } from '../context/Redux';
import { formatTasksFromGraphQL } from '../formatters/graphQLResults';
import { graphQLFetchTasksFailed, graphQLFetchTasksSucceeded } from '../redux/actions/tasksSearch';
import { isJitaUserSelector, ownerIdSelector } from '../redux/selectors/auth';
import { getTasksByIdsSelector } from '../redux/selectors/tasks';
import { getSearchResultsSelector } from '../redux/selectors/tasksSearchResults';
import { tempViewStateSelector } from '../redux/selectors/tempViewState';
import { GROUP_NAMES, reportNewTasksError } from '../tracking/errors';
import { sortStateToCRMSearchSorts } from '../utils/crmSearchRequestBuilder';
import { processFiltersForSearchRequest } from '../utils/processFiltersForSearchRequest';
import { getSearchKey } from '../utils/tasksSearchResultsKeyHelpers';
import { useCrmSearchQuery } from './useCrmSearchQuery';
import { useTaskTablePageSize } from './useTaskTablePageSize';
import { rethrowError } from 'UIComponents/core/PromiseHandlers';
export function useSearchRequestOptionsForView(viewId) {
  const isJitaUser = useSelector(isJitaUserSelector);
  const ownerIdOverride = useSelector(ownerIdSelector);
  const tempViewState = useSelector(tempViewStateSelector)[viewId];
  const {
    pageSize
  } = useTaskTablePageSize();
  return useMemo(() => {
    if (!tempViewState) {
      return {
        loading: true,
        searchKey: null,
        crmSearchQuery: null
      };
    }
    const {
      filters,
      searchText,
      sortState,
      offset
    } = tempViewState;
    const crmSearchFilters = processFiltersForSearchRequest({
      filters,
      isJitaUser,
      ownerIdOverride,
      excludePlaceholders: [],
      addTaskFamily: true
    });
    const crmSearchQuery = {
      filterGroups: [{
        filters: crmSearchFilters
      }],
      sorts: sortStateToCRMSearchSorts(sortState),
      count: pageSize,
      offset,
      query: searchText
    };
    const searchKey = getSearchKey(String(viewId), crmSearchQuery);
    return {
      loading: false,
      searchKey,
      crmSearchQuery
    };
  }, [isJitaUser, ownerIdOverride, pageSize, tempViewState, viewId]);
}
export const useCrmSearchGraphQLViewSearch = ({
  crmSearchQuery,
  searchKey
}) => {
  const dispatch = useDispatch();
  const shouldMakeSearchRequest = searchKey && crmSearchQuery;
  const onSuccess = useCallback(data => {
    const {
      crmObjectsSearch: {
        hasMore,
        offset,
        results,
        total
      }
    } = data;

    // recently deleted tasks can be returned from graphql with null properties
    const tasks = formatTasksFromGraphQL(results).filter(task => Boolean(getProperty(task, CREATED_DATE)));
    dispatch(graphQLFetchTasksSucceeded({
      hasMore,
      offset,
      searchKey: searchKey,
      tasks,
      total
    }));
  }, [dispatch, searchKey]);
  const onError = useCallback(error => {
    reportNewTasksError(error, {
      groupName: GROUP_NAMES.CRM_SEARCH,
      functionName: 'graphQLFetchTasksFailed',
      extra: {
        message: `failed for search query ${JSON.stringify(searchKey)}`
      }
    });
    dispatch(graphQLFetchTasksFailed({
      error,
      searchKey: searchKey
    }));
  }, [dispatch, searchKey]);
  const {
    loading,
    refetch,
    data
  } = useCrmSearchQuery(crmSearchQuery, {
    onCompleted: onSuccess,
    onError,
    pollInterval: TASKS_POLL_INTERVAL,
    skipQuery: !shouldMakeSearchRequest
  });
  const hasData = data && data.crmObjectsSearch;
  const onRefreshSearch = useCallback(() => {
    refetch().catch(rethrowError);
  }, [refetch]);
  const onDelayedRefreshSearch = useCallback(() => {
    setTimeout(() => {
      refetch().catch(rethrowError);
    }, BULK_ACTION_LAG_TIME);
    refetch().catch(rethrowError);
  }, [refetch]);
  return {
    loading: loading && !hasData,
    onRefreshSearch,
    onDelayedRefreshSearch
  };
};
export const useHydratedTaskSearchResults = searchKey => {
  const getSearchResults = useSelector(getSearchResultsSelector);
  const searchResults = getSearchResults(searchKey);
  const taskIds = searchResults ? searchResults.taskIds : [];
  const getTasksByIds = useSelector(getTasksByIdsSelector);
  const tasks = getTasksByIds(taskIds);
  return useMemo(() => {
    const {
      hasMore,
      offset,
      total,
      error,
      loading,
      uninitialized
    } = searchResults;
    return {
      hasMore,
      offset,
      total,
      tasks,
      error,
      loading,
      uninitialized
    };
  }, [tasks, searchResults]);
};

// helper hooks for using views and search results
export const useSearchResultsOfView = viewId => {
  const {
    searchKey
  } = useSearchRequestOptionsForView(viewId);
  const getSearchResults = useSelector(getSearchResultsSelector);
  if (!searchKey) return {
    loading: true,
    uninitialized: true
  };
  const searchResults = getSearchResults(searchKey);
  return searchResults;
};