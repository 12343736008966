import invariant from 'react-utils/invariant';
import { COLUMN_TO_CRM_SEARCH_PROPERTY } from '../constants/table';
import { mergeFiltersIntoFilterGroups } from './commonSearchRequestBuilderHelpers';
import { getCreatedBeforeNowFilter } from './crmSearchFilters';
export function sortStateToCRMSearchSorts({
  propertyName,
  sortOrder = 'ASC'
} = {}) {
  if (!propertyName || !COLUMN_TO_CRM_SEARCH_PROPERTY[propertyName]) {
    return [{
      property: 'hs_object_id',
      order: 'ASC'
    }];
  }
  const {
    searchProperty
  } = COLUMN_TO_CRM_SEARCH_PROPERTY[propertyName];
  return [{
    property: searchProperty,
    order: sortOrder
  }, {
    property: 'hs_object_id',
    order: 'ASC'
  }];
}
export function addCreatedBeforeNowFilterToCRMSearchRequest(searchRequestOptions) {
  invariant((searchRequestOptions === null || searchRequestOptions === void 0 ? void 0 : searchRequestOptions.filterGroups) && searchRequestOptions.filterGroups.length > 0, 'Received a search request with 0 filterGroups');
  return Object.assign({}, searchRequestOptions, {
    filterGroups: mergeFiltersIntoFilterGroups(searchRequestOptions === null || searchRequestOptions === void 0 ? void 0 : searchRequestOptions.filterGroups, [getCreatedBeforeNowFilter()])
  });
}