import http from 'hub-http/clients/apiClient';
import { registerMutation, useMutation } from 'data-fetching-client';
import { useCallback } from 'react';
import { PINNED_VIEWS_QUERY, PinnedViewsQuery } from './useFetchPinnedViews';
import { SingleViewByIdQuery } from '../../singleView/hooks/useFetchSingleView';
import { pinnedViewsClient } from '../../clients/pinnedViewsClient';

/* this takes in an objectTypeId and a namespace and returns a formatted url to set pinned views from the views api
 */
const getUrl = ({
  objectTypeId,
  namespace
}) => {
  const baseUrl = `sales/v3/views/${objectTypeId}/pinned`;
  const urlParams = new URLSearchParams({
    namespace
  });
  return encodeURI(`${baseUrl}?${urlParams.toString()}`);
};
export const SetPinnedViewsMutation = registerMutation({
  fieldName: PINNED_VIEWS_QUERY,
  args: ['objectTypeId', 'namespace', 'viewIds'],
  fetcher: ({
    objectTypeId,
    namespace,
    viewIds
  }) => http.put(getUrl({
    objectTypeId,
    namespace
  }), {
    data: viewIds
  }).then(() => {
    //we need to return the viewIds we just set for apollo cache
    return viewIds;
  })
});
export const useSetPinnedViews = ({
  objectTypeId,
  namespace
}) => {
  const [mutate, result] = useMutation(SetPinnedViewsMutation);
  const mutateCallback = useCallback(viewIds => mutate({
    optimisticResponse: {
      pinnedViews: viewIds
    },
    variables: {
      viewIds,
      objectTypeId,
      namespace
    },
    update(cache, {
      data
    }) {
      if (data) {
        const allViews = viewIds.map(viewId => {
          var _cache$readQuery;
          return (_cache$readQuery = cache.readQuery({
            query: SingleViewByIdQuery,
            variables: {
              id: viewId,
              objectTypeId,
              namespaceName: namespace
            }
          })) === null || _cache$readQuery === void 0 ? void 0 : _cache$readQuery.singleViewById;
        });
        const missingViewIds = viewIds.filter((__viewId, index) => !allViews[index]);
        if (!missingViewIds.length) {
          // Only sync pinned views into state if they all exist in the cache
          pinnedViewsClient.updateCache({
            pinnedViews: allViews,
            objectTypeId,
            namespace
          });
        }
        cache.writeQuery({
          query: PinnedViewsQuery,
          variables: {
            objectTypeId,
            namespace
          },
          data
        });
      }
      return data;
    }
  }), [mutate, namespace, objectTypeId]);
  return [mutateCallback, result];
};