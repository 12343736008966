import { registerMutation, useMutation } from 'data-fetching-client';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import { updateView } from '../api/views';
import { GROUP_NAMES, reportAndDisplayError } from '../tracking/errors';
import { getViewEditSuccessAlert } from '../utils/alerts';
import { noop } from '../utils/noop';
import { viewFieldName } from './useFetchViewById';
import { viewCategoriesFieldName } from './useFetchViewCategories';
import { fetchViewPermissionsQuery } from './useFetchViewPermissionsById';
import { pinnedViewsFieldName } from './usePinnedViews';
export const updateViewQuery = registerMutation({
  fieldName: 'view',
  args: ['view'],
  fetcher: updateView
});
export const useUpdateView = ({
  onCompleted = noop
} = {}) => {
  return useMutation(updateViewQuery, {
    onCompleted(data) {
      FloatingAlertStore.addAlert(getViewEditSuccessAlert({
        name: data.view.name
      }));
      onCompleted(data);
    },
    onError(err) {
      reportAndDisplayError(err, {
        messageI18nKey: 'manageViews.alerts.updateView.failure.message',
        titleI18nKey: 'manageViews.alerts.updateView.failure.title'
      }, {
        groupName: GROUP_NAMES.TASK_VIEWS,
        functionName: 'useUpdateView'
      });
    },
    refetchQueries: [fetchViewPermissionsQuery],
    update: cache => {
      cache.evict({
        fieldName: pinnedViewsFieldName
      });
      cache.evict({
        fieldName: viewFieldName
      });
      cache.evict({
        fieldName: viewCategoriesFieldName
      });

      // garbage collect unreachable objects after evicting cache
      // recommended by Apollo https://www.apollographql.com/docs/react/caching/garbage-collection/#cacheevict
      cache.gc();
    }
  });
};