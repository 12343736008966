import { createReducer } from '@reduxjs/toolkit';
import setIn from 'transmute/setIn';
import getIn from 'transmute/getIn';
import { graphQLFetchTasksFailed, graphQLFetchTasksSucceeded } from '../actions/tasksSearch';
import { addNewTask } from '../actions/tasksCRUD';
const INITIAL_STATE = {};
export const tasksSearchResults = createReducer(INITIAL_STATE, builder => builder.addCase(graphQLFetchTasksSucceeded, (state, action) => {
  const taskIds = action.payload.tasks.map(task => task.objectId);
  const searchKey = action.payload.searchKey;
  return setIn(searchKey, {
    hasMore: action.payload.hasMore,
    loading: false,
    offset: action.payload.offset,
    success: true,
    taskIds,
    timestamp: Date.now(),
    total: action.payload.total
  })(state);
}).addCase(addNewTask, (state, action) => {
  if (!action.payload.searchKey || !action.payload.task.objectId) {
    return state;
  }
  const searchKey = action.payload.searchKey;
  const currentTaskIds = getIn([...searchKey, 'taskIds'])(state);
  return setIn(searchKey, {
    taskIds: [action.payload.task.objectId, ...currentTaskIds]
  })(state);
}).addCase(graphQLFetchTasksFailed, (state, action) => {
  if (getIn([...action.payload.searchKey, 'success'])(state)) {
    return state;
  }
  return setIn(action.payload.searchKey, {
    loading: false,
    error: action.payload.error
  })(state);
}));