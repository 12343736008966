import { isMultienum } from 'customer-data-objects/property/PropertyIdentifier';
import { parseMultiEnumValue } from 'customer-data-property-utils/parseMultiEnumValue';
import { INVALID, PENDING, VALID } from '../validation/PropertyValidationStatus';
const isConditionalPropertyRuleActive = ({
  objectTypeId,
  getFormValue,
  properties,
  rule
}) => {
  if (objectTypeId !== rule.objectTypeId) {
    return false;
  }
  const {
    fieldName,
    fieldValue: ruleValue
  } = rule.controllingField;
  const currentValue = getFormValue(fieldName);
  const property = properties[fieldName];
  if (isMultienum(property)) {
    const values = parseMultiEnumValue(ruleValue);
    const currentValues = parseMultiEnumValue(currentValue);
    return values.every(_ => currentValues.includes(_));
  }
  return ruleValue === currentValue;
};
export const getConditionalPropertyDependencies = (objectTypeId, getFormValue, properties, conditionalPropertyRules) => {
  const activeRules = {};
  for (const rule of conditionalPropertyRules) {
    const active = isConditionalPropertyRuleActive({
      rule,
      objectTypeId,
      getFormValue,
      properties
    });
    if (active) {
      const controllingFieldName = rule.controllingField.fieldName;
      activeRules[controllingFieldName] = (Object.prototype.hasOwnProperty.call(activeRules, controllingFieldName) ? activeRules[controllingFieldName] : []).concat(rule.dependentFields);
    }
  }
  return activeRules;
};

/** Flattens and returns a pre-order traversal of the graph */
export const getAllFormInputs = initialInputs => {
  function _getDependents(inputs, input) {
    inputs.push(input);
    return input.dependents.reduce(_getDependents, inputs);
  }
  return initialInputs.reduce(_getDependents, []);
};

/** Flattens and returns a pre-order traversal of the graph */
export const getAllFormProperties = nodes => {
  function _getDependents(_nodes, node) {
    _nodes.push(node);
    return node.dependents.reduce(_getDependents, _nodes);
  }
  return nodes.reduce(_getDependents, []);
};

/**
 * Flattens and returns a pre-order traversal of the graph, omitting
 * the initial properties (the first layer). Only dependent properties
 * (and their dependents, etc.) are returned.
 */
export const getDependentFormProperties = nodes => {
  function _getDependents(_nodes, node) {
    _nodes.push(node);
    return node.dependents.reduce(_getDependents, _nodes);
  }
  return nodes.reduce((properties, property) => property.dependents.reduce(_getDependents, properties), []);
};
export const getFormPropertyNames = nodes => {
  return getAllFormProperties(nodes).reduce((acc, property) => {
    acc.add(property.propertyName);
    return acc;
  }, new Set());
};

/** returns additional values in first set */
export function difference(a, b) {
  return [...a].filter(x => !b.has(x));
}
const emptyFormPropertiesDifference = {
  added: [],
  removed: []
};
export const getFormPropertiesDifference = (prev, next) => {
  if (prev === next) {
    return emptyFormPropertiesDifference;
  }
  const prevPropertyNames = getFormPropertyNames(prev);
  const nextPropertyNames = getFormPropertyNames(next);
  return {
    added: difference(nextPropertyNames, prevPropertyNames),
    removed: difference(prevPropertyNames, nextPropertyNames)
  };
};

/** Deduplicates inputs by property name. All inputs for same property have same value */
export const getUniqueFormInputs = inputs => {
  const seen = new Set();
  const uniqueInputs = getAllFormInputs(inputs).reduce((acc, input) => {
    if (!seen.has(input.property.name)) {
      seen.add(input.property.name);
      acc.push(input);
    }
    return acc;
  }, []);
  return uniqueInputs;
};
export const toPropertyWithDependents = property => {
  return Object.assign({}, property, {
    nodeId: property.propertyName,
    isRequired: Boolean(property.isRequired),
    dependents: [],
    parent: null
  });
};

/**
 * Builds the structure for showing nested properties within the form.
 * The output is a tree where the following conditions hold:
 *   - Each initial property in the form is the root of a branch that contains
 *     a node for each dependent property. This is recursive, but duplicates
 *     are pruned per-branch to prevent cycles.
 *   - Conditional rules are applied to any top-level properties that have been
 *     changed, any nested properties, or all properties if `alwaysShowConditionalProperties`
 *     is flagged to true.
 *   - If a dependent property would appear twice, prefer the closest to root
 *
 * General strategy to build:
 *   - Level-order traversal of the dependency tree
 *   - Treat each initial form property as their own branch and keep
 *     set of visited nodes per branch. Evaluate rules of already seen nodes
 *     once, but do not add them to queue.
 *   - Keep track of any conditional rules in a global set while building.
 *     Apply rules as a single pass through tree after all nodes evaluated.
 */
export const applyConditionalPropertyRules = (properties, dependencies, changedProperties, alwaysShowConditionalProperties) => {
  const queues = [];
  const visited = [];
  const requiredProperties = new Set();
  const initialProperties = [];
  for (const property of properties) {
    const node = toPropertyWithDependents(property);
    queues.push([node]);
    initialProperties.push(node);
    visited.push(new Set());
  }
  let depth = 1; // we manually traverse depth = 0 (aka the root) above
  while (queues.some(branch => branch.length > 0)) {
    for (let branchIndex = 0; branchIndex < queues.length; branchIndex++) {
      const level = queues[branchIndex];
      const branchVisited = visited[branchIndex];
      const nextLevel = [];
      for (const node of level) {
        const propertyName = node.propertyName;
        if (node.isRequired) {
          requiredProperties.add(propertyName);
        }
        if (branchVisited.has(propertyName)) {
          continue;
        }
        if (node.parent) {
          node.parent.dependents.push(node);
        }
        if (alwaysShowConditionalProperties || depth > 1 || changedProperties.has(propertyName)) {
          const dependents = (dependencies[propertyName] || []).map(dependent => ({
            nodeId: `${node.nodeId}/${dependent.fieldName}`,
            propertyName: dependent.fieldName,
            isRequired: dependent.required,
            dependents: [],
            parent: node
          }));
          nextLevel.push(...dependents);
        }
        branchVisited.add(propertyName);
      }
      queues[branchIndex] = nextLevel;
    }
    depth++;
  }
  const markRequired = node => {
    node.isRequired = requiredProperties.has(node.propertyName);
    node.dependents.forEach(markRequired);
  };
  initialProperties.forEach(markRequired);
  return initialProperties;
};

// injects conditional property options dependents and builds the tree using applyConditionalPropertyRules
export const applyConditionalPropertyOptionsRules = ({
  formProperties,
  formDependencies,
  changedProperties,
  alwaysShowConditionalPropertyOptionsWithErrors,
  conditionalPropertyOptionsRules
}) => {
  const formDependenciesWithCPO = Object.assign({}, formDependencies);
  for (const activeConditionalPropertyOptionsRule of conditionalPropertyOptionsRules) {
    const controllingFieldName = activeConditionalPropertyOptionsRule.controllingField.fieldName;
    const dependentFieldName = activeConditionalPropertyOptionsRule.dependentFieldName;
    const dependentField = {
      fieldName: dependentFieldName,
      required: false,
      // this is a best case scenario. There could be a display order greater than array length
      // but it will not have an impact because we are always pushing CPO dependents to the end
      // since we are recreating CP dependencies on rerenders, CPO dependents will not sneak into CP dependents
      displayOrder: formDependenciesWithCPO[controllingFieldName] ? formDependenciesWithCPO[controllingFieldName].length : 0
    };

    // there are no dependents for this controlling field, so we create a new dependencies array
    if (!Object.prototype.hasOwnProperty.call(formDependenciesWithCPO, controllingFieldName)) {
      formDependenciesWithCPO[controllingFieldName] = [dependentField];
      continue;
    }

    // the dependent field already exists as a dependent for this controlling field.
    // we skip adding to avoid duplicates
    if (formDependenciesWithCPO[controllingFieldName].some(dependent => dependent.fieldName === dependentFieldName)) {
      continue;
    }

    // we push the new dependent into the existing array
    formDependenciesWithCPO[controllingFieldName].push(dependentField);
  }
  return applyConditionalPropertyRules(formProperties, formDependenciesWithCPO, changedProperties, alwaysShowConditionalPropertyOptionsWithErrors);
};
export const buildPropertiesFormInitializingState = () => ({
  validationStatus: 'INITIALIZING',
  initializing: true,
  initializationFailed: false,
  loading: false,
  invalid: false,
  saveable: false,
  errors: new Map()
});
export const buildPropertiesFormInitializationFailedState = () => ({
  validationStatus: 'INITIALIZATION_FAILED',
  initializing: false,
  initializationFailed: true,
  loading: false,
  invalid: false,
  saveable: false,
  errors: new Map()
});
export const buildPropertiesFormPendingState = () => ({
  validationStatus: PENDING,
  initializing: false,
  initializationFailed: false,
  loading: true,
  invalid: false,
  saveable: false,
  errors: new Map()
});
export const buildPropertiesFormValidState = () => ({
  validationStatus: VALID,
  initializing: false,
  initializationFailed: false,
  loading: false,
  invalid: false,
  saveable: true,
  errors: new Map()
});
export const buildPropertiesFormInvalidState = errors => ({
  validationStatus: INVALID,
  initializing: false,
  initializationFailed: false,
  loading: false,
  invalid: true,
  saveable: false,
  errors
});
export const getMultiEnumPropertiesWithValue = ({
  propertyNames,
  properties,
  getFormValue
}) => [...propertyNames].filter(propertyName => properties[propertyName] && isMultienum(properties[propertyName]) && getFormValue(propertyName));