import { useLayoutEffect, useRef } from 'react';
import { useTableDispatch } from '../../redux/reduxHooks';
import { clearSelection, unselectObjectsNoLongerInTable } from '../../selection/redux/selectionSlice';
import { useResetVisibleIndexes } from '../../virtualization/VirtualizationContext';
const areRowIdsEqual = (currentRows, prevRows) => prevRows && prevRows.length === currentRows.length && prevRows.every((rowId, index) => currentRows[index] === rowId);
export const useResetTableState = ({
  disableScrollReset,
  rowIds,
  tableContainerRef,
  resetObserver
}) => {
  const resetVisibleIndexes = useResetVisibleIndexes();
  const prevRowIdsRef = useRef(null);
  const dispatch = useTableDispatch();
  useLayoutEffect(() => {
    if (areRowIdsEqual(rowIds, prevRowIdsRef.current)) {
      return;
    }
    prevRowIdsRef.current = rowIds;
    if (!disableScrollReset) {
      // Clear selection state
      dispatch(clearSelection());

      // Reset to the default visible rows/cols
      resetVisibleIndexes();

      // Teardown and re-create the observer so that we
      // check visiblity once more
      resetObserver();

      // Scroll the table to the top if possible
      if (tableContainerRef.current) {
        tableContainerRef.current.scrollTop = 0;
      }
    } else {
      dispatch(unselectObjectsNoLongerInTable(rowIds));
    }
  }, [disableScrollReset, dispatch, resetObserver, rowIds, tableContainerRef, resetVisibleIndexes]);
};