import { DUE_DATE } from 'customer-data-objects/task/TaskPropertyNames';
import enviro from 'enviro';
import { VIEW_ID_KEYS } from './views';
export function _mapToFilterNameArray(filterObjArray) {
  return filterObjArray.map(filter => filter.property);
}
function reportConflictErrors({
  destination,
  source
}) {
  if (enviro.deployed() && enviro.getShort() === 'prod') {
    return;
  }
  const destinationFilterNames = _mapToFilterNameArray(destination);
  const sourceFilterNames = _mapToFilterNameArray(source);
  const conflictingFilterNames = sourceFilterNames.filter(sourceFilterName => destinationFilterNames.includes(sourceFilterName));
  if (conflictingFilterNames.length > 0) {
    // This may not be an error since some views may not use all filters
    console.warn('Conflicting view filters. These filters appear twice in the search object:', conflictingFilterNames);
  }
}
export function _getNonConflictingSourceFilters({
  destination,
  source
}) {
  const destinationFilterNames = _mapToFilterNameArray(destination);
  return source.filter(sourceFilter => !destinationFilterNames.includes(sourceFilter.property));
}

/*
 * The destination array takes priority. We will throw errors
 * in local dev mode when conflicts occur.
 **/
export function _mergeFilterArrays({
  destination,
  source
}) {
  reportConflictErrors({
    destination,
    source
  });
  const sourceFiltersToMerge = _getNonConflictingSourceFilters({
    destination,
    source
  });
  return [...destination, ...sourceFiltersToMerge];
}
const HUBSPOT_DEFINED_VIEWS_WITH_DUE_DATE_FILTER = [VIEW_ID_KEYS.OPEN_TASKS_DUE_TODAY, VIEW_ID_KEYS.OPEN_TASKS_OVERDUE, VIEW_ID_KEYS.OPEN_TASKS_UPCOMING];
export function getDisabledViewFilters({
  id
}) {
  if (HUBSPOT_DEFINED_VIEWS_WITH_DUE_DATE_FILTER.includes(id)) {
    return [DUE_DATE];
  }
  return [];
}
export function mergeFiltersIntoFilterGroups(filterGroups, filters) {
  return filterGroups === null || filterGroups === void 0 ? void 0 : filterGroups.map(filterGroup => ({
    filters: _mergeFilterArrays({
      destination: filterGroup.filters,
      source: filters
    })
  }));
}