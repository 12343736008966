import { registerQuery, useQuery } from 'data-fetching-client';
import { getViewCategories } from '../api/views';
export const viewCategoriesFieldName = 'viewCategories';
export const fetchViewCategoriesQuery = registerQuery({
  fieldName: viewCategoriesFieldName,
  args: ['categories', 'offset', 'searchQuery'],
  fetcher: getViewCategories
});
export const useFetchViewCategories = ({
  categories,
  offset,
  searchQuery = '',
  options = {}
}) => {
  const queryResults = useQuery(fetchViewCategoriesQuery, Object.assign({}, options, {
    onCompleted: results => {
      if (typeof options.onCompleted === 'function') {
        options.onCompleted(results);
      }
    },
    variables: {
      categories,
      offset,
      searchQuery
    }
  }));
  return queryResults;
};