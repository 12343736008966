import { DUE_DATE, RELATIVE_REMINDERS, REMINDERS } from 'customer-data-objects/task/TaskPropertyNames';
import { useMemo } from 'react';
import { getDefaultDueDate, getDefaultReminder } from 'task-forms-lib/utils/TaskDefaults';
import { getRemindersAsUnitAndCount } from 'task-forms-lib/utils/formatRelativeDatePresets';
import { DEFAULT_DUE_DATE_PRESET, DEFAULT_DUE_TIME, DEFAULT_REMINDER_PRESET } from '../constants/userSettingsKeys';
import { useSelector } from '../context/Redux';
import { createUserSettingWithLoadingSelector } from '../redux/selectors/userSettings';
const defaultDueDatePresetSelector = createUserSettingWithLoadingSelector(DEFAULT_DUE_DATE_PRESET);
const defaultDueTimeSelector = createUserSettingWithLoadingSelector(DEFAULT_DUE_TIME);
const defaultReminderPresetSelector = createUserSettingWithLoadingSelector(DEFAULT_REMINDER_PRESET);
export function useTaskDefaults() {
  const dueDateBundle = useSelector(defaultDueDatePresetSelector);
  const dueTimeBundle = useSelector(defaultDueTimeSelector);
  const reminderBundle = useSelector(defaultReminderPresetSelector);
  return useMemo(() => {
    if ([dueDateBundle, dueTimeBundle, reminderBundle].some(bundle => bundle.isLoading || bundle.hasError)) {
      return {};
    }
    const defaultDueDate = getDefaultDueDate(dueDateBundle.value, dueTimeBundle.value);
    const defaultReminder = getDefaultReminder(reminderBundle.value, defaultDueDate);
    return {
      [DUE_DATE]: defaultDueDate,
      [RELATIVE_REMINDERS]: getRemindersAsUnitAndCount(defaultDueDate, [defaultReminder]),
      [REMINDERS]: defaultReminder
    };
  }, [dueDateBundle, dueTimeBundle, reminderBundle]);
}