// This function had to be pulled out of the UISearchInput file so that the function can be spied on using Jasmine

import { getTextWidth } from './MeasureText';

/** @internal */
export const computeMinimumSearchMessageOverflow = (wrapperElement, iconElement, helpTextElement, inputElement) => {
  // Determine whether there's enough space to show the minimum search message inline (#2869)
  if (!wrapperElement || !iconElement || !helpTextElement) return undefined;
  const wrapperWidth = wrapperElement.offsetWidth - iconElement.offsetWidth;
  const searchMessageWidth = helpTextElement.offsetWidth;
  const textWidth = getTextWidth(inputElement, null);
  const newMinimumSearchMessageOverflows = searchMessageWidth > wrapperWidth - textWidth;
  return newMinimumSearchMessageOverflows;
};