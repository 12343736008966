import { useSelector } from '../context/Redux';
import { isJitaUserSelector, ownerIdSelector } from '../redux/selectors/auth';
import { jitaSelectedUserId as jitaSelectedUserIdQuery } from '../query/jitaSelectedUserId';
import { useQuery } from 'data-fetching-client';
export function useJitaSelectedUserId() {
  const isJitaUser = useSelector(isJitaUserSelector);
  const selectedOwnerId = useSelector(ownerIdSelector);
  const userIdResponse = useQuery(jitaSelectedUserIdQuery, {
    variables: {
      isJitaUser,
      selectedOwnerId
    },
    skip: !isJitaUser || selectedOwnerId === -1
  });
  if (userIdResponse.loading || !userIdResponse.data) {
    return null;
  }
  return userIdResponse.data.jitaSelectedUserId;
}