import { useSelector } from '../context/Redux';
import { DONT_SHOW_FOLLOW_UP_TASK } from '../constants/userSettingsKeys';
import { createUserSettingWithLoadingSelector } from '../redux/selectors/userSettings';
export function useFollowUpTasksSetting() {
  const {
    isLoading,
    value
  } = useSelector(createUserSettingWithLoadingSelector(DONT_SHOW_FOLLOW_UP_TASK));
  return {
    isLoading,
    // usually with user settings, not set = false = the happy path.
    // We negate here so we can use affirmative variable names
    shouldShowFollowUpTasks: !value
  };
}