import { configureStore } from '@reduxjs/toolkit';
import { widthsReducer } from '../resizing/redux/widthsSlice';
import { selectionReducer } from '../selection/redux/selectionSlice';
export const createStore = ({
  initialWidths
}) => configureStore({
  reducer: {
    selection: selectionReducer,
    widths: widthsReducer
  },
  devTools: {
    name: 'customer-data-table'
  },
  preloadedState: {
    widths: {
      widths: initialWidths
    }
  }
});