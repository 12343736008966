import { TASK_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import omit from 'transmute/omit';
import { DESC } from '../constants/sorts';
import * as ViewIdMapping from '../constants/ViewIdMapping';
import { DEFAULT, TEMP_VIEW_ONLY_KEYS } from '../constants/views';
export const decorateDefaultView = view => {
  // TODO: This can be removed once the BE is able to return the string form
  // of default view ids. Need this to create URL for showing view in table
  // See https://hubspot.slack.com/archives/C028JC2EF7U/p1638824354163200?thread_ts=1638824154.162500&cid=C028JC2EF7U

  // @ts-expect-error ignore that ID may be number or not in mapping
  const id = ViewIdMapping.lookup(view.id);

  // Default views always have string ids like "all", "my", etc.
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number' is not assignab... Remove this comment to see the full error message
  const isDefaultView = isNaN(id);
  const unparsedView = isDefaultView ? Object.assign({}, view, {
    id,
    type: DEFAULT,
    ownerId: -1
  }) : view;
  return unparsedView;
};
function parseStringifiedFields(view) {
  return Object.assign({}, view, {
    state: JSON.parse(view.state),
    columns: JSON.parse(view.columns),
    filters: JSON.parse(view.filters)
  });
}
export function parseView(viewResponse) {
  return parseStringifiedFields(decorateDefaultView(viewResponse));
}

/**
 * Formats and adds fields to view definition
 * @param {Object} view the view definition to be formatted
 * @returns
 */
export const formatViewForSave = view => {
  return Object.assign({}, view, {
    columns: JSON.stringify((view.columns || []).map(col => ({
      name: col.name
    }))),
    state: JSON.stringify(view.state),
    filters: JSON.stringify(view.filters),
    // Remove field, eventual goal is for this field to be removed from data model and we don't need this in tasks
    // https://paper.dropbox.com/doc/Devons-long-term-goals-for-views-and-how-he-thinks-we-get-there--Bax2u8Qk94ZQqH9FUgcUG0c0Ag-SceSZ87NqUokSnrfzleOT#:uid=475618121450380359071124&h2=Phase-3:-Clean-up-the-view-dat
    // https://hubspot.slack.com/archives/C028JC2EF7U/p1638827615169800?thread_ts=1638824154.162500&cid=C028JC2EF7U
    collectionType: undefined,
    objectTypeId: TASK_TYPE_ID
  });
};
export const transformTempViewIntoSavedViewFormat = view => {
  const viewWithOmittedKeys = omit(TEMP_VIEW_ONLY_KEYS, view);
  const {
    sortState
  } = view;
  return Object.assign({}, viewWithOmittedKeys, {
    state: {
      sortKey: sortState.propertyName,
      sortColumnName: sortState.propertyName,
      order: sortState.sortOrder === DESC ? 1 : -1
    }
  });
};