import { TASKS_VIEW_ALL, TASKS_VIEW_TEAM_ONLY, TASKS_VIEW_UNASSIGNED } from '../constants/scopes';
import { useSelector } from '../context/Redux';
import { createHasOneOfScopesSelector } from '../redux/selectors/auth';
import { useTeammateUserIds } from './useTeammateUserIds';
const taskViewAllSelector = createHasOneOfScopesSelector([TASKS_VIEW_ALL]);
const taskViewUnassignedSelector = createHasOneOfScopesSelector([TASKS_VIEW_UNASSIGNED]);
const taskViewTeamSelector = createHasOneOfScopesSelector([TASKS_VIEW_TEAM_ONLY]);
export function useTaskViewPermissions() {
  const canViewAllTasks = useSelector(taskViewAllSelector);
  const canViewUnassignedTasks = useSelector(taskViewUnassignedSelector);
  const canViewTeamTasks = useSelector(taskViewTeamSelector);
  const canViewOwnedTasksOnly = !canViewAllTasks && !canViewUnassignedTasks && !canViewTeamTasks;
  const {
    teammateUserIds
  } = useTeammateUserIds();
  return {
    currentUserTeammateIds: teammateUserIds,
    canViewAllTasks,
    canViewUnassignedTasks,
    canViewTeamTasks,
    canViewOwnedTasksOnly
  };
}