import { registerMutation, registerQuery, useMutation, useQuery } from 'data-fetching-client';
import { useCallback, useMemo } from 'react';
import { getPinnedViewDefinitions, putPinnedViews } from '../api/views';
import { reportAndDisplayError, GROUP_NAMES } from '../tracking/errors';
import { rethrowError } from 'UIComponents/core/PromiseHandlers';
export const pinnedViewsFieldName = 'pinnedViews';
export const pinnedViewsQuery = registerQuery({
  fieldName: pinnedViewsFieldName,
  fetcher: getPinnedViewDefinitions
});
export const SET_PINNED_VIEWS_MUTATION = registerMutation({
  fieldName: pinnedViewsFieldName,
  fetcher: putPinnedViews
});
export const usePinnedViews = () => {
  const {
    data,
    loading
  } = useQuery(pinnedViewsQuery, {
    notifyOnNetworkStatusChange: true // allows loading state to be updated on refetches
  });
  const pinnedViews = useMemo(() => {
    var _data$pinnedViews;
    return (data === null || data === void 0 || (_data$pinnedViews = data.pinnedViews) === null || _data$pinnedViews === void 0 ? void 0 : _data$pinnedViews.results) || [];
  }, [data]);
  const [setPinnedViewsMutation] = useMutation(SET_PINNED_VIEWS_MUTATION, {
    update(cache, {
      data: updatedPinnedViewDefinitions
    }) {
      cache.writeQuery({
        query: pinnedViewsQuery,
        data: {
          pinnedViews: {
            results: updatedPinnedViewDefinitions === null || updatedPinnedViewDefinitions === void 0 ? void 0 : updatedPinnedViewDefinitions.pinnedViews
          }
        }
      });
    },
    onError: error => {
      reportAndDisplayError(error, {
        messageI18nKey: 'tasksAlerts.views.pinViewError'
      }, {
        groupName: GROUP_NAMES.TASK_VIEWS,
        functionName: 'toggleViewPinned',
        extra: {
          pinnedViews
        }
      });
    }
  });
  const toggleViewPinned = useCallback(selectedViewDefinition => {
    const viewAlreadyPinned = !!pinnedViews.find(view => view.id === selectedViewDefinition.id);
    const newPinnedViews = viewAlreadyPinned ? pinnedViews.filter(view => view.id !== selectedViewDefinition.id) : [...pinnedViews, selectedViewDefinition];
    setPinnedViewsMutation({
      variables: {
        allPinnedViewDefinitions: newPinnedViews
      }
    }).catch(rethrowError);
  }, [setPinnedViewsMutation, pinnedViews]);
  const setDefaultView = useCallback(newDefaultView => {
    const newPinnedViews = [newDefaultView, ...pinnedViews.filter(view => view.id !== newDefaultView.id)];
    setPinnedViewsMutation({
      variables: {
        allPinnedViewDefinitions: newPinnedViews
      }
    }).catch(rethrowError);
  }, [pinnedViews, setPinnedViewsMutation]);

  // This should be called with an array of views
  const reorderViews = useCallback(reorderedViews => {
    setPinnedViewsMutation({
      variables: {
        allPinnedViewDefinitions: reorderedViews
      }
    }).catch(rethrowError);
  }, [setPinnedViewsMutation]);
  return {
    pinnedViews,
    setDefaultView,
    toggleViewPinned,
    reorderViews,
    pinnedViewsLoading: loading
  };
};