import { useCallback, useState } from 'react';
import { COLUMN, ROW } from '../types/observerTypes';
import { useUpdateVisibleIndexes } from '../VirtualizationContext';
function performInAnimationFrame(callback) {
  if (window.requestAnimationFrame) {
    window.requestAnimationFrame(callback);
    return;
  }
  window.setTimeout(callback);
}

//from MDN
//https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
//```
//Be aware that your callback is executed on the main thread.
//It should operate as quickly as possible;
//if anything time-consuming needs to be done, use Window.requestIdleCallback().
//```
const handleIntersectionFactory = updateVisibleIndexes => {
  const handleIntersection = entries => {
    performInAnimationFrame(() => {
      const visCols = {};
      const visRows = {};
      let hasUpdate = false;
      for (const {
        target,
        isIntersecting
      } of entries) {
        const type = target.getAttribute('data-observer-type');
        const id = target.getAttribute('data-observer-id');

        // Skip elements without a type or id. This should never actually happen,
        // so long as we're setting the `data-observer-*` attributes properly.
        if (!id || !type) {
          continue;
        }
        if (type === COLUMN && isIntersecting) {
          visCols[id] = isIntersecting;
          hasUpdate = true;
        } else if (type === ROW && isIntersecting) {
          visRows[id] = isIntersecting;
          hasUpdate = true;
        }
      }
      if (hasUpdate) {
        updateVisibleIndexes({
          rows: visRows,
          columns: visCols
        });
      }
    });
  };
  return handleIntersection;
};
export const useObserveTableContainerRef = () => {
  const [observer, setObserver] = useState(null);
  const updateVisibleIndexes = useUpdateVisibleIndexes();

  // This is called a callback ref. When passed to the `ref` prop of a component,
  // this function is called twice whenever the component changes its underlying dom node:
  // Once with `null` (so we can clean up) and once with the new node. We use this to
  // clean up the current observer before making a new one with a new root.
  const handleObserveRef = useCallback(scrollElementRef => {
    setObserver(currentObserver => {
      if (currentObserver) {
        currentObserver.disconnect();
      }
      if (!scrollElementRef) {
        return null;
      }
      const handleIntersection = handleIntersectionFactory(updateVisibleIndexes);
      return new IntersectionObserver(handleIntersection, {
        root: scrollElementRef
      });
    });
  }, [updateVisibleIndexes]);
  return {
    handleObserveRef,
    observer
  };
};