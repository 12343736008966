import { stringify } from 'hub-http/helpers/params';
import Raven from 'raven-js';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from '../context/Redux';
import { setViewColumns } from '../redux/actions/tempViewState';
import { updateTempViewCrmSearchQuery } from '../redux/actions/tempViewState';
import { viewLink } from '../utils/links';
import { useQueryParams } from './useQueryParams';
import { useActiveViewId } from './viewTabs';
const FILTERS_QUERY_PARAM = 'filters';
const COLUMNS_QUERY_PARAM = 'columns';
const getJsonFiltersFromQueryParams = value => {
  try {
    return JSON.parse(decodeURIComponent(value));
  } catch (err) {
    Raven.captureException(err, {
      extra: {
        function: 'getJsonFiltersFromQueryParams',
        value
      }
    });
    return JSON.parse(value);
  }
};
const getJsonColumnsFromQueryParams = value => {
  if (!value) {
    return null;
  }
  try {
    return JSON.parse(decodeURIComponent(value));
  } catch (err) {
    Raven.captureException(err, {
      extra: {
        function: 'getJsonColumnsFromQueryParams',
        value
      }
    });
    return null;
  }
};
export const useHandleSpecialQueryParams = () => {
  const history = useHistory();
  const params = useQueryParams();
  const currentViewId = useActiveViewId();
  const dispatch = useDispatch();
  const hasParamsThatMakeFilters = (FILTERS_QUERY_PARAM in params);
  const hasParamsThatMakeColumns = (COLUMNS_QUERY_PARAM in params);
  useEffect(() => {
    if (hasParamsThatMakeFilters) {
      const nextFilters = getJsonFiltersFromQueryParams(String(params[FILTERS_QUERY_PARAM]) || '[]');
      dispatch(updateTempViewCrmSearchQuery({
        viewId: currentViewId,
        crmSearchFilters: nextFilters
      }));
    }
    if (hasParamsThatMakeColumns) {
      const parsedColumns = getJsonColumnsFromQueryParams(String(params[COLUMNS_QUERY_PARAM]));
      if (parsedColumns) {
        const formattedColumns = parsedColumns.map(name => ({
          name
        }));
        dispatch(setViewColumns({
          columns: formattedColumns,
          viewId: currentViewId
        }));
      }
    }

    // Setting params to undefined will remove them from the url
    const queryWithoutParsedParams = Object.assign({}, params, {
      [FILTERS_QUERY_PARAM]: undefined,
      [COLUMNS_QUERY_PARAM]: undefined
    });
    if (hasParamsThatMakeFilters || hasParamsThatMakeColumns) {
      const newQuery = stringify(queryWithoutParsedParams);
      history.replace(`${viewLink({
        viewId: currentViewId
      })}${newQuery ? `?${newQuery}` : ''}`);
    }
  }, [currentViewId, dispatch, history, hasParamsThatMakeColumns, hasParamsThatMakeFilters, params]);
  return !hasParamsThatMakeFilters && !hasParamsThatMakeColumns;
};